import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "../App.css";

import { AnimatePresence } from "framer-motion";

import Header from "./Header";

import LandingPage from "./LandingPage";
import Projects from "./Projects";

const App = () => {
  return (
    <div className="app">
      <AnimatePresence>
        <BrowserRouter>
          <Header />
          <Route path="/" exact component={LandingPage} />
          <Route path="/projects" exact component={Projects} />
        </BrowserRouter>
      </AnimatePresence>
    </div>
  );
};

export default App;
