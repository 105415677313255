export const ProjectButtons = ({ links }) => {
  const anchorElements = links.map((link) => {
    const btnText = link.includes("github.com") ? "Github" : "Live";

    return (
      <a
        key={link}
        href={link}
        target="_blank"
        rel="noreferrer"
        className="slider-button"
      >
        {btnText}
      </a>
    );
  });

  return <div className="project-buttons">{anchorElements}</div>;
};
