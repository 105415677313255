import business1 from "../assets/businessPad-pictures/business1.jpg";
import business2 from "../assets/businessPad-pictures/business2.jpg";
import business3 from "../assets/businessPad-pictures/business3.jpg";

import bay1 from "../assets/bay360-pictures/bay1.jpg";
import bay3 from "../assets/bay360-pictures/bay3.jpg";
import bay4 from "../assets/bay360-pictures/bay4.jpg";

import wisdom1 from "../assets/wisdom-pictures/wisdom1.jpg";
import wisdom2 from "../assets/wisdom-pictures/wisdom2.jpg";
import wisdom3 from "../assets/wisdom-pictures/wisdom3.jpg";

import bonsai1 from "../assets/bonsaiPlus-pictures/Bonsai-plus1.jpg";
import bonsai2 from "../assets/bonsaiPlus-pictures/Bonsai-plus2.jpg";
import bonsai3 from "../assets/bonsaiPlus-pictures/Bonsai-plus3.jpg";

import survival3 from "../assets/survival-island-pictures/survival-island-win.jpg";
import survival1 from "../assets/survival-island-pictures/survival-island.jpg";
import survival2 from "../assets/survival-island-pictures/survival-island3.jpg";

export const survivalIslandDetails = {
  projectName: "Survival Island",
  projectDescription: `Minimalist survival game, inspired by the text based browser game, A Dark Room.
  Front-end built with Vue and Babylon.js, served on OCI using Terraform and Docker.
  Submission to DeveloperWeek 2023 Hackathon. 
  Winner-Oracle Sponsor Challenge #2 - Build a Multiplayer Game with 1v1 Winner/Loser Game Logic.
  
  `,
  buttonLinks: [
    "129.146.190.242",
    "https://github.com/donmarcos/devweek2023oci",
  ],
  img1: survival1,
  img2: survival2,
  img3: survival3,
};

export const wisdomProjectDetails = {
  projectName: "The Wisdom Project",
  projectDescription: `The Wisdom Project was created to help document and share valuable
    knowledge from users around the world. CRUD app created with Vue.Js and Firebase`,
  buttonLinks: [
    "https://wisdomproject.app/#/home",
    "https://github.com/bstewart3/wisdom-vue-project",
  ],
  img1: wisdom1,
  img2: wisdom2,
  img3: wisdom3,
};

export const bonsaiPlusProjectDetails = {
  projectName: "Bonsai+",
  projectDescription: `This is a social blogging platform for bonsai enthusiasts inspired by sites like Dev.to and medium, created with Next 13, React, and Firebase`,
  buttonLinks: [
    "https://bonsai-plus-af3346jqr-bstewart3.vercel.app/enter",
    "https://github.com/bstewart3/bonsai-plus",
  ],
  img1: bonsai1,
  img2: bonsai2,
  img3: bonsai3,
};

export const businessPadDetails = {
  projectName: "businessPad",
  projectDescription: `BusinessPad makes
    taking notes on a new business idea simple.  CRUD app created with React and Firebase`,
  buttonLinks: [
    "https://buisnesspad.web.app/home",
    "https://github.com/bstewart3/business-pad",
  ],
  img1: business1,
  img2: business2,
  img3: business3,
};

export const bay360Details = {
  projectName: "Bay360",
  projectDescription: `Bay360 creates interactive 360° Virtual Tours of commercial and
    residential properties around The Bay Area. Created with Next 13, React, and Tailwind`,
  buttonLinks: [
    "https://bay360.vercel.app/",
    "https://github.com/bstewart3/bay360",
  ],
  img1: bay1,
  img2: bay3,
  img3: bay4,
};
