import React from "react";
import AnimationWrapper from "./AnimationWrapper";
import Box from "./Box";

import { HashLink } from "react-router-hash-link";

import HeroImage from "./assets/IMG_0396-min.jpg";

class LandingPage extends React.Component {
  render() {
    return (
      <div id="landing-page" className="landing-page">
        <div className="landing-wrapper">
          <div className="display-area">
            <div className="landing-container">
              <div className="left">
                <AnimationWrapper>
                  <h2 className="title1">Hi, Im Brennan..</h2>
                  <h2 className="title2">Web Developer With A Passion For</h2>
                  <h1 className="title3">Craftsmanship</h1>
                  <div className="landing-buttons">
                    <HashLink to="#slider">
                      <button className="button">Check Out My Projects</button>
                    </HashLink>
                    <a
                      href="mailto:brennanstewart43@gmail.com"
                      className="button"
                    >
                      Contact Me
                    </a>
                  </div>
                </AnimationWrapper>
              </div>
              <div className="right">
                <AnimationWrapper>
                  <img
                    className="hero-image"
                    src={HeroImage}
                    alt="Person on top of Half Dome in Yosemite National Park"
                  ></img>
                </AnimationWrapper>
              </div>
            </div>
          </div>
        </div>
        <div id="slider" className="project-slider">
          <Box />
          <HashLink to="#header" className="featured-text">
            <h3>Back to top ↑</h3>
          </HashLink>
        </div>
      </div>
    );
  }
}

export default LandingPage;
