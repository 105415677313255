import React from "react";
import { HeaderButtons } from "./HeaderButtons";

import { Link } from "react-router-dom";

import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import Resume from "./assets/Brennan-resume.pdf";

const Header = () => {
  return (
    <div id="header" className="nav">
      <Link to="/" id="logo" className="logo">
        <h1>Brennan Stewart</h1>
      </Link>

      <HeaderButtons
        resume={Resume}
        resumeText={"Resume"}
        gitHubLink={"https://github.com/bstewart3"}
        gitHubIcon={<GitHubIcon />}
        linkedInLink={"https://www.linkedin.com/in/brennan-stewart-9b7b0922a/"}
        linkedInIcon={<LinkedInIcon />}
      />
    </div>
  );
};

export default Header;
