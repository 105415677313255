import React from "react";

import Slider from "react-slick";
import { ProjectButtons } from "./ProjectButtons";
import { ProjectText } from "./ProjectText";
import { SliderCard } from "./SliderCard";

import {
  bay360Details,
  bonsaiPlusProjectDetails,
  businessPadDetails,
  survivalIslandDetails,
  wisdomProjectDetails,
} from "./utils/constants";

export default function SimpleSlider() {
  const settings = {
    dots: false,
    infinite: false,
    fade: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slick-container">
      <Slider {...settings}>
        <SliderCard>
          <ProjectText
            projectName={bay360Details.projectName}
            projectDescription={bay360Details.projectDescription}
          />
          <div className="image-slider">
            <Slider {...settings} dots={true} infinite={true} fade={true}>
              <img src={bay360Details.img1} alt="Bay360 landing page"></img>
              <img src={bay360Details.img2} alt="bay360 graphic design"></img>
              <img
                src={bay360Details.img3}
                alt="bay360 real estate carousel"
              ></img>
            </Slider>
          </div>
          <ProjectButtons links={bay360Details.buttonLinks} />
        </SliderCard>

        <SliderCard>
          <ProjectText
            projectName={survivalIslandDetails.projectName}
            projectDescription={survivalIslandDetails.projectDescription}
          />
          <div className="image-slider">
            <Slider {...settings} dots={true} infinite={true} fade={true}>
              <img
                src={survivalIslandDetails.img1}
                alt="Bay360 landing page"
              ></img>
              <img
                src={survivalIslandDetails.img2}
                alt="bay360 graphic design"
              ></img>
              {/* <img
                src={survivalIslandDetails.img3}
                alt="bay360 real estate carousel"
              ></img> */}
            </Slider>
          </div>
          <ProjectButtons links={survivalIslandDetails.buttonLinks} />
        </SliderCard>

        <SliderCard>
          <ProjectText
            projectName={bonsaiPlusProjectDetails.projectName}
            projectDescription={bonsaiPlusProjectDetails.projectDescription}
          />
          <div className="image-slider">
            <Slider {...settings} dots={true} infinite={true} fade={true}>
              <img
                src={bonsaiPlusProjectDetails.img1}
                alt="Bay360 landing page"
              ></img>
              <img
                src={bonsaiPlusProjectDetails.img2}
                alt="bay360 graphic design"
              ></img>
              <img
                src={bonsaiPlusProjectDetails.img3}
                alt="bay360 real estate carousel"
              ></img>
            </Slider>
          </div>
          <ProjectButtons links={bonsaiPlusProjectDetails.buttonLinks} />
        </SliderCard>

        <SliderCard>
          <ProjectText
            projectName={wisdomProjectDetails.projectName}
            projectDescription={wisdomProjectDetails.projectDescription}
          />
          <div className="image-slider">
            <Slider {...settings} dots={true} infinite={true} fade={true}>
              <img
                src={wisdomProjectDetails.img1}
                alt="Landing page of The Wisdom Project"
              ></img>
              <img
                src={wisdomProjectDetails.img2}
                alt="Wisdom Project UI"
              ></img>
              <img
                src={wisdomProjectDetails.img3}
                alt="A Wisdom project post"
              ></img>
            </Slider>
          </div>
          <ProjectButtons links={wisdomProjectDetails.buttonLinks} />
        </SliderCard>

        <SliderCard>
          <ProjectText
            projectName={businessPadDetails.projectName}
            projectDescription={businessPadDetails.projectDescription}
          />
          <div className="image-slider">
            <Slider {...settings} dots={true} infinite={true} fade={true}>
              <img
                src={businessPadDetails.img1}
                alt="businessPad landing page"
              ></img>
              <img src={businessPadDetails.img2} alt="businessPad UI"></img>
              <img
                src={businessPadDetails.img3}
                alt="businessPad Identity Login"
              ></img>
            </Slider>
          </div>
          <ProjectButtons links={businessPadDetails.buttonLinks} />
        </SliderCard>
      </Slider>
    </div>
  );
}
