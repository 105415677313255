import SimpleSlider from "./Slider";

import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const Box = () => {
  const boxVariant = {
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    hidden: { opacity: 0, x: 200 },
  };
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      ref={ref}
      variants={boxVariant}
      className="box"
      initial="hidden"
      animate={control}
      transition={{ ease: "easeOut", duration: 4 }}
    >
      <h2 id="featured-text">Featured Projects:</h2>
      <SimpleSlider />
    </motion.div>
  );
};

export default Box;
