export const HeaderButtons = ({
  resume,
  resumeText,
  gitHubLink,
  gitHubIcon,
  linkedInLink,
  linkedInIcon,
}) => {
  return (
    <div className="right-menu">
      <a href={resume} className="nav-item" target="_blank" rel="noreferrer">
        <h2>{resumeText}</h2>
      </a>

      <div className="icon-links">
        <a
          className="icon-link giticon"
          aria-label="Check Out Brennan's Github"
          href={gitHubLink}
          target="_blank"
          rel="noreferrer"
        >
          {gitHubIcon}
        </a>
        <a
          className="icon-link linkedinicon"
          aria-label="Check Out Brennan's Github"
          href={linkedInLink}
          target="_blank"
          rel="noreferrer"
        >
          {linkedInIcon}
        </a>
      </div>
    </div>
  );
};
