import React from "react";

import Box from "./Box";

class Projects extends React.Component {
  render() {
    return (
      <div className="project-page">
        <div id="project-slider" className="project-slider">
          <Box />
        </div>
      </div>
    );
  }
}

export default Projects;
