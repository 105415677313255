import React from "react";

import { motion } from "framer-motion";

const AnimationWrapper = ({ children }) => {
  return (
    <motion.div
      animate={{
        y: ["-150px", "50px", "0px"],
      }}
      transition={{
        delay: 0.3,
        type: "spring",
        duration: 0.5,
      }}
    >
      {children}
    </motion.div>
  );
};

export default AnimationWrapper;
